var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.shownavandfooter === true)?_c('navbar'):_vm._e(),_c('v-container',{staticClass:"mt-4 mb-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"alert mt-4"},[(_vm.error_msg != null)?_c('v-col',{staticClass:"col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"},[_c('v-alert',{attrs:{"dense":"","type":"warning"}},[_vm._v(_vm._s(_vm.error_msg))])],1):_vm._e(),(_vm.success_msg != null)?_c('v-col',{staticClass:"col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"},[_c('v-alert',{attrs:{"dense":"","type":"success"}},[_vm._v(_vm._s(_vm.success_msg))])],1):_vm._e()],1),_c('v-row',{staticClass:"d-flex text-main justify-center"},[_c('v-col',{attrs:{"cols":_vm.shownavandfooter === true ? '11' : '12'}},[_c('v-data-table',{class:_vm.shownavandfooter === true
        ? 'elevation-1 nzar-font radius-15'
        : 'nzar-font radius-15',attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading,"search":_vm.search,"sort-by":"name","footer-props":{
        showFirstLastPage: true,
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
        'items-per-page-text': _vm.langkeyword('PERPAGE'),
        'items-per-page-options': _vm.perPages(),
    },"items-per-page":10},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-icon',[_vm._v("mdi-cart-plus")]),_vm._v(" "+_vm._s(_vm.langkeyword("ORDERS")))],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-delete-circle")]),_vm._v(" "+_vm._s(_vm.langkeyword("DELETE"))+" ")],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE"))+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red lighten-2","dark":""},on:{"click":function($event){return _vm.deleteOrders(_vm.ordersObject.id)}}},[_c('v-icon',[_vm._v("mdi-delete-circle")]),_vm._v(" "+_vm._s(_vm.langkeyword("DELETE"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"justify":"end","color":"red darken-2","dark":""},on:{"click":_vm.closeDeleteDialog}},[_c('v-icon',[_vm._v(" mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.langkeyword("CLOSE"))+" ")],1)],1)],1)],1),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[(_vm.permissionExist('#search_orders'))?_c('v-text-field',{staticClass:"nzar-font",attrs:{"append-icon":"mdi-magnify","label":_vm.langkeyword('SEARCH'),"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [(_vm.permissionExist('#view_orders'))?_c('v-icon',{staticClass:"mr-2 blue white--text px-2 py-2 rounded",attrs:{"small":""},on:{"click":function($event){return _vm.viewOrder(item)}}},[_vm._v(" mdi-eye ")]):_vm._e(),(_vm.permissionExist('#view_accounts'))?_c('v-icon',{staticClass:"mr-2 blue white--text px-2 py-2 rounded",attrs:{"small":""},on:{"click":function($event){return _vm.viewAccount(item)}}},[_vm._v(" mdi-account-plus ")]):_vm._e(),(_vm.permissionExist('#delete_orders'))?_c('v-icon',{staticClass:"mr-2 red white--text px-2 py-2 rounded",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDialog(item)}}},[_vm._v(" mdi-delete-circle ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('br'),_c('br'),_c('br'),(_vm.shownavandfooter === true)?_c('dashboardFooter'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }