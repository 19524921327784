<template>
    <div>
        <navbar v-if="shownavandfooter === true"></navbar>
        <v-container fluid class="mt-4 mb-4">
            <center>
                <h1>{{ langkeyword('REPORT') }}</h1>
            </center>
        </v-container>
        <br /><br /><br />
        <dashboardFooter v-if="shownavandfooter === true" />
    </div>
</template>
<script>
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
    props: {
        shownavandfooter: {
            default: true,
            type: Boolean,
        },
    },
    components: {
        navbar,
        dashboardFooter,
    }
}
</script>