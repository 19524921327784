<template>
    <div>
        <navbar></navbar>
        <v-container fluid class="mt-4 mb-4">
            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>

            <v-row class="d-flex text-main justify-center">
                <v-col cols="11">
                    <v-data-table :headers="headers" :items="productcolors" :loading="loading" :search="search"
                        sort-by="name" class="elevation-1 nzar-font radius-15" :footer-props="{
                        showFirstLastPage: true,
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                        'items-per-page-text': langkeyword('PERPAGE'),
                        'items-per-page-options': perPages(),
                    }" :items-per-page="10">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title><v-icon>mdi-invert-colors</v-icon>
                                    {{ langkeyword("COLORS") }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialog" max-width="700px" persistent>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="success" :style="permissionExist('#add_products')
                        ? 'color: white;float:right'
                        : 'display:none;'
                        " class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                                            <v-icon> mdi-plus-circle </v-icon>
                                            {{ langkeyword("ADD") }}
                                        </v-btn>
                                    </template>
                                    <v-card class="radius-15 bg-mode">
                                        <v-card-title>
                                            <span class="nzar-font" v-if="isAdd() === true"><v-icon>
                                                    mdi-plus-circle</v-icon>
                                                {{ langkeyword("ADD") }}</span>
                                            <span class="nzar-font" v-if="isEdit() === true"><v-icon>
                                                    mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}</span>
                                        </v-card-title>
                                        <v-card-text class="radius-15">
                                            <v-container>
                                                <v-form>
                                                    <v-select class="d-none"
                                                        v-if="isAdd() === true || isEdit() === true"
                                                        v-model="productinfo.id" :items="products"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('PRODUCT')" dense></v-select>

                                                    <v-select v-if="isAdd() === true || isEdit() === true"
                                                        v-model="productcolorsObject.color_id" :items="colors"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('COLOR')" dense></v-select>
                                                </v-form>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn class="bg-main white--text" v-if="isAdd() === true" dark
                                                @click="saveProductColors">
                                                <v-icon dark> mdi-plus-circle</v-icon>
                                                {{ langkeyword("SAVE") }}
                                            </v-btn>
                                            <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                                                @click="updateProductColors(productcolorsObject.id)">
                                                <v-icon dark> mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" dark @click="closeMainDialog">
                                                <v-icon dark>mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <v-icon>mdi-delete-circle</v-icon>
                                            {{ langkeyword("DELETE") }}
                                        </v-card-title>
                                        <v-card-text>
                                            {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="red lighten-2" dark
                                                @click="deleteProductColors(productcolorsObject.id)">
                                                <v-icon>mdi-delete-circle</v-icon>
                                                {{ langkeyword("DELETE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                                                <v-icon> mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-spacer></v-spacer>

                                <v-row>
                                    <v-col cols="12" md="10">
                                        <v-text-field v-if="permissionExist('#search_products')" class="nzar-font"
                                            v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                                            hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon small class="mr-2 blue white--text px-2 py-2 rounded"
                                @click="openProductColorSizes(item)">
                                mdi-size-xxs
                            </v-icon>
                            <v-icon small class="mr-2 blue white--text px-2 py-2 rounded"
                                @click="openProductColorAvatars(item)">
                                mdi-image-area
                            </v-icon>
                            <v-icon v-if="permissionExist('#edit_products')" small
                                class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                                mdi-pencil-circle
                            </v-icon>
                            <v-icon v-if="permissionExist('#delete_products')" small
                                class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                                mdi-delete-circle
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
import Vue from "vue";
import ProductsRequests from "../../../requests/dashboard/product/Products";
import ProductColorsRequests from "../../../requests/dashboard/product/ProductColors";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data: () => ({
        productinfo: {},
        dialog: false,
        dialogDelete: false,
        snackbar: true,
        search: "",
        loading:
            Vue.prototype.getters().getproductcolors == null ||
                Vue.prototype.getters().getproductcolors == "" ||
                Vue.prototype.getters().getproductcolors == undefined
                ? true
                : false,
        error_msg: null,
        success_msg: null,
        headers: [
            {
                text: Vue.prototype.langkeyword("COLORS"),
                value: "colors_" + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("ACTIONS"),
                value: "edit",
                sortable: false,
                align: "center",
            },
        ],
        CURRENTFORM: "add",
        productcolorsObject: {
            user_id: Vue.prototype.dashboardAuthId(),
            color_id: "",
            product_id: '',
        },
    }),
    watch: {
        options: {
            handler() {
                this.readData();
            },
        },
    },
    mounted() {
        this.readData();
    },
    computed: {
        productcolors() {
            return this.$store.getters.getproductcolors.filter(item => {
                return item.product_id == this.$route.params.id
            })
        },
        products() {
            return this.$store.getters.getproducts;
        },
        colors() {
            return this.$store.getters.getcolors;
        },
    },
    methods: {
        isAdd() {
            if (this.CURRENTFORM === "add") {
                return true;
            } else {
                return false;
            }
        },
        isEdit() {
            if (this.CURRENTFORM === "edit") {
                return true;
            } else {
                return false;
            }
        },
        cleanMessages() {
            this.productcolorsObject = { user_id: Vue.prototype.dashboardAuthId() };
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        validate(params, callback) {
            this.error_msg = null;
            params.forEach((param) => {
                if (param == "" || param == undefined || param == null) {
                    this.error_msg = this.langkeyword("DATA_REQUIRED");
                }
            });
            if (this.error_msg == null) {
                return callback();
            }
            this.cleanMessages();
        },
        editDialog(item) {
            this.CURRENTFORM = "edit";
            this.productcolorsObject = Object.assign({}, item);
            this.dialog = true;
        },
        lockDialog(item) {
            this.CURRENTFORM = "lock";
            this.productcolorsObject = Object.assign({}, item);
            this.dialog = true;
        },
        deleteDialog(item) {
            this.CURRENTFORM = "delete";
            this.productcolorsObject = Object.assign({}, item);
            this.dialogDelete = true;
        },
        closeMainDialog() {
            this.dialog = false;
            this.$nextTick(() => {
                this.productcolorsObject = Object.assign({}, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        closeDeleteDialog() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.productcolorsObject = Object.assign({}, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        readData() {


            ProductsRequests.getOne(
                {
                    getOneProducts: true,
                    product_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                        setTimeout(() => {
                            this.$router.push("/*");
                        }, 1000);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.productcolorsObject.product_id = res.data.data.id;
                        this.productinfo = res.data.data;
                    }
                    this.cleanMessages();
                }
            ),




                // this.productcolors = this.$store.getters.getproductcolors.filter(pc => {
                //     return pc.product_id == this.$route.params.id
                // });
                setTimeout(() => {
                    if (!this.permissionExist("#view_products")) {
                        this.$router.push("/dashboard/home");
                    }
                }, 500);

        },
        saveProductColors() {
            this.validate(
                [
                    this.$route.params.id,
                    this.productcolorsObject.color_id,
                ],
                () => {

                    ProductColorsRequests.add(
                        {
                            addProductColors: true,
                            color_id: this.productcolorsObject.color_id,
                            product_id: this.$route.params.id,
                            user_id: Vue.prototype.dashboardAuthId(),
                        },
                        (res) => {
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.productcolors = res.data.data;
                                this.productcolors = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        updateProductColors(productcolorId) {
            this.validate(
                [
                    this.$route.params.id,
                    this.productcolorsObject.color_id,
                ],
                () => {
                    ProductColorsRequests.edit(
                        {
                            editProductColors: true,
                            productcolor_id: productcolorId,
                            color_id: this.productcolorsObject.color_id,
                            product_id: this.$route.params.id,
                            user_id: Vue.prototype.dashboardAuthId()
                        },
                        (res) => {
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.productcolors = res.data.data;
                                this.productcolors = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        deleteProductColors(productcolorId) {
            ProductColorsRequests.delete(
                { deleteProductColors: true, productcolor_id: productcolorId },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.productcolors = res.data.data;
                        this.productcolors = res.data.data;
                    }
                }
            );
            this.cleanMessages();
            this.closeDeleteDialog();
        },
        openProductColorSizes(item) {
            this.$router.push(this.$route.params.id + '/sizes/' + item.id)
        },
        openProductColorAvatars(item) {
            this.$router.push(this.$route.params.id + '/avatars/' + item.id)
        }
    },
};
</script>