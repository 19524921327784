<template>
    <div>
        <navbar v-if="shownavandfooter === true"></navbar>
        <v-container fluid class="mt-4 mb-4">
            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>

            <v-row class="d-flex text-main justify-center">
                <v-col :cols="shownavandfooter === true ? '11' : '12'">
                    <v-data-table :headers="headers" :items="orders" :loading="loading" :search="search" sort-by="name"
                        :class="shownavandfooter === true
            ? 'elevation-1 nzar-font radius-15'
            : 'nzar-font radius-15'
            " :footer-props="{
            showFirstLastPage: true,
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            'items-per-page-text': langkeyword('PERPAGE'),
            'items-per-page-options': perPages(),
        }" :items-per-page="10">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title><v-icon>mdi-cart-plus</v-icon>
                                    {{ langkeyword("ORDERS") }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <v-icon>mdi-delete-circle</v-icon>
                                            {{ langkeyword("DELETE") }}
                                        </v-card-title>
                                        <v-card-text>
                                            {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="red lighten-2" dark @click="deleteOrders(ordersObject.id)">
                                                <v-icon>mdi-delete-circle</v-icon>
                                                {{ langkeyword("DELETE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                                                <v-icon> mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-spacer></v-spacer>

                                <v-row>
                                    <v-col cols="12" md="10">
                                        <v-text-field v-if="permissionExist('#search_orders')" class="nzar-font"
                                            v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                                            hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon v-if="permissionExist('#view_orders')" small
                                class="mr-2 blue white--text px-2 py-2 rounded" @click="viewOrder(item)">
                                mdi-eye
                            </v-icon>
                            <v-icon v-if="permissionExist('#view_accounts')" small
                                class="mr-2 blue white--text px-2 py-2 rounded" @click="viewAccount(item)">
                                mdi-account-plus
                            </v-icon>
                            <v-icon v-if="permissionExist('#delete_orders')" small
                                class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                                mdi-delete-circle
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter v-if="shownavandfooter === true" />
    </div>
</template>

<script>
import Vue from "vue";
import OrdersRequests from "../../../requests/dashboard/customer/Orders";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
    props: {
        shownavandfooter: {
            default: true,
            type: Boolean,
        },
    },
    components: {
        navbar,
        dashboardFooter,
    },
    data: () => ({
        dialog: false,
        dialogDelete: false,
        snackbar: true,
        orders: [],
        search: "",
        loading:
            Vue.prototype.getters().getorders == null ||
                Vue.prototype.getters().getorders == "" ||
                Vue.prototype.getters().getorders == undefined
                ? true
                : false,
        error_msg: null,
        success_msg: null,
        headers: [
            { text: "#", value: "id", align: "center" },
            {
                text: Vue.prototype.langkeyword("ORDERSTATUS"),
                value: "orderstatuses_" + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("CODE"),
                value: "ordercode",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("FULLNAME"),
                value: 'accounts_fullname',
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("DATE"),
                value: 'created_at',
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("ACTIONS"),
                value: "edit",
                sortable: false,
                align: "center",
            },
        ],
        CURRENTFORM: "add",
        ordersObject: {
            user_id: Vue.prototype.dashboardAuthId(),
            name: "",
            phone: "",
        },
    }),
    watch: {
        options: {
            handler() {
                this.readData();
            },
        },
    },
    mounted() {
        this.readData();
    },
    methods: {
        isAdd() {
            if (this.CURRENTFORM === "add") {
                return true;
            } else {
                return false;
            }
        },
        isEdit() {
            if (this.CURRENTFORM === "edit") {
                return true;
            } else {
                return false;
            }
        },
        cleanMessages() {
            this.ordersObject = {
                user_id: Vue.prototype.dashboardAuthId(),
                name: "",
                phone: "",
            };
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        validate(params, callback) {
            this.error_msg = null;
            params.forEach((param) => {
                console.log(params);
                if (param == "" || param == undefined || param == null) {
                    this.error_msg = this.langkeyword("DATA_REQUIRED");
                }
            });
            if (this.error_msg == null) {
                return callback();
            }
            this.cleanMessages();
        },
        editDialog(item) {
            this.CURRENTFORM = "edit";
            this.ordersObject = Object.assign(
                {
                    user_id: Vue.prototype.dashboardAuthId(),
                    name: "",
                    phone: "",
                },
                item
            );
            this.dialog = true;
        },
        lockDialog(item) {
            this.CURRENTFORM = "lock";
            this.ordersObject = Object.assign(
                {
                    user_id: Vue.prototype.dashboardAuthId(),
                    name: "",
                    phone: "",
                },
                item
            );
            this.dialog = true;
        },
        deleteDialog(item) {
            this.CURRENTFORM = "delete";
            this.ordersObject = Object.assign(
                {
                    user_id: Vue.prototype.dashboardAuthId(),
                    name: "",
                    phone: "",
                },
                item
            );
            this.dialogDelete = true;
        },
        closeMainDialog() {
            this.dialog = false;
            this.$nextTick(() => {
                this.ordersObject = Object.assign(
                    {
                        user_id: Vue.prototype.dashboardAuthId(),
                        name: "",
                        phone: "",
                    },
                    this.defaultItem
                );
                this.CURRENTFORM = "add";
            });
        },
        closeDeleteDialog() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.ordersObject = Object.assign(
                    {
                        user_id: Vue.prototype.dashboardAuthId(),
                        name: "",
                        phone: "",
                    },
                    this.defaultItem
                );
                this.CURRENTFORM = "add";
            });
        },
        readData() {
            this.orders = this.$store.getters.getorders;
            setTimeout(() => {
                if (!this.permissionExist("#view_orders")) {
                    this.$router.push("/dashboard/home");
                }
            }, 500);
        },
        deleteOrders(orderId) {
            OrdersRequests.delete(
                { deleteOrders: true, order_id: orderId },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.orders = res.data.data;
                        this.orders = res.data.data;
                    }
                }
            );
            this.cleanMessages();
            this.closeDeleteDialog();
        },
        viewOrder(item) {
            this.$router.push("/dashboard/customer/orders/" + item.id);
        },
        viewAccount(item) {
            this.$router.push("/dashboard/customer/accounts/" + item.account_id);
        },
    },
};
</script>