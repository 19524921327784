<template>
    <div>
        <navbar></navbar>
        <v-container fluid class="mt-4 mb-4">
            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>

            <v-row class="d-flex text-main justify-center">
                <v-col cols="11">
                    <v-data-table :headers="headers" :items="products" :loading="loading" :search="search"
                        sort-by="name" class="elevation-1 nzar-font radius-15" :footer-props="{
                        showFirstLastPage: true,
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                        'items-per-page-text': langkeyword('PERPAGE'),
                        'items-per-page-options': perPages(),
                    }" :items-per-page="10">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title><v-icon>mdi mdi-basket</v-icon>
                                    {{ langkeyword("PRODUCTS") }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialog" max-width="1500px" persistent>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="success"
                                            :style="permissionExist('#add_products') ? 'color: white;float:right' : 'display:none;'"
                                            class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                                            <v-icon> mdi-plus-circle </v-icon>
                                            {{ langkeyword("ADD") }}
                                        </v-btn>
                                    </template>
                                    <v-card class="radius-15 bg-mode">
                                        <v-card-title>
                                            <span class="nzar-font" v-if="isAdd() === true"><v-icon>
                                                    mdi-plus-circle</v-icon>
                                                {{ langkeyword("ADD") }}</span>
                                            <span class="nzar-font" v-if="isEdit() === true"><v-icon>
                                                    mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}</span>
                                        </v-card-title>
                                        <v-card-text class="radius-15">
                                            <v-container>
                                                <v-form>
 
                                                    <v-img v-if="CURRENTFORM === 'edit'" width="100%" height="100%"
                                                        class="mx-auto rounded-lg"
                                                        :src="uploadPath(productsObject.avatar)">
                                                    </v-img>

                                                    <v-file-input v-model="productsObject.avatar" id="avatar"
                                                        name="avatar" required accept="image/png, image/jpeg, image/bmp"
                                                        prepend-icon="mdi-camera" :label="langkeyword('IMAGE')">
                                                    </v-file-input>


                                                    <v-text-field class="nzar-font" append-icon="mdi-format-title"
                                                        v-model="productsObject.ku_name"
                                                        :label="langkeyword('KU_NAME')">
                                                    </v-text-field>
                                                    <v-text-field class="nzar-font" append-icon="mdi-format-title"
                                                        v-model="productsObject.ar_name"
                                                        :label="langkeyword('AR_NAME')">
                                                    </v-text-field>
                                                    <v-text-field class="nzar-font" append-icon="mdi-format-title"
                                                        v-model="productsObject.en_name"
                                                        :label="langkeyword('EN_NAME')">
                                                    </v-text-field>

                                                    <v-text-field type="number" step="any" min="0" class="nzar-font"
                                                        append-icon="mdi-format-title" v-model="productsObject.price"
                                                        :label="langkeyword('PRICE')">
                                                    </v-text-field>

                                                    <v-select v-if="isAdd() === true || isEdit() === true"
                                                        v-model="category_id" :items="categories"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('CATEGORY')" dense></v-select>

                                                    <v-select
                                                        v-if="(isAdd() === true || isEdit() === true) && category_id != null"
                                                        v-model="productsObject.subcategory_id" :items="subcategories"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('SUBCATEGORY')" dense></v-select>
                                                    <v-select
                                                        v-if="(isAdd() === true || isEdit() === true) && category_id != null"
                                                        v-model="productsObject.brand_id" :items="brands"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('BRAND')" dense></v-select>
                                                    <v-select
                                                        v-if="(isAdd() === true || isEdit() === true) && category_id != null"
                                                        v-model="productsObject.age_id" :items="ages"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('AGE')" dense></v-select>
                                                    <br>
                                                    <v-textarea dense filled class="text-xs-center"
                                                        v-model="productsObject.ku_detail"
                                                        :label="langkeyword('KU_DETAIL')"></v-textarea>
                                                    <v-textarea dense filled class="text-xs-center"
                                                        v-model="productsObject.ar_detail"
                                                        :label="langkeyword('AR_DETAIL')"></v-textarea>
                                                    <v-textarea dense filled class="text-xs-center"
                                                        v-model="productsObject.en_detail"
                                                        :label="langkeyword('EN_DETAIL')"></v-textarea>

                                                </v-form>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn class="bg-main white--text" v-if="isAdd() === true" dark
                                                @click="saveProducts">
                                                <v-icon dark> mdi-plus-circle</v-icon>
                                                {{ langkeyword("SAVE") }}
                                            </v-btn>
                                            <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                                                @click="updateProducts(productsObject.id)">
                                                <v-icon dark> mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" dark @click="closeMainDialog">
                                                <v-icon dark>mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <v-icon>mdi-delete-circle</v-icon>
                                            {{ langkeyword("DELETE") }}
                                        </v-card-title>
                                        <v-card-text>
                                            {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="red lighten-2" dark
                                                @click="deleteProducts(productsObject.id)">
                                                <v-icon>mdi-delete-circle</v-icon>
                                                {{ langkeyword("DELETE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                                                <v-icon> mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-spacer></v-spacer>

                                <v-row>
                                    <v-col cols="12" md="10">
                                        <v-text-field v-if="permissionExist('#search_products')" class="nzar-font"
                                            v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                                            hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon v-if="permissionExist('#edit_products')" small
                                class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                                mdi-pencil-circle
                            </v-icon>
                            <v-icon v-if="permissionExist('#delete_products')" small
                                class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                                mdi-delete-circle
                            </v-icon>
                            <v-icon v-if="permissionExist('#view_products')" small
                                class="mr-2 info white--text px-2 py-2 rounded" @click="openProductColors(item)">
                                mdi-eye
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
import Vue from "vue";
import ProductsRequests from "../../../requests/dashboard/product/Products";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data: () => ({
        dialog: false,
        dialogDelete: false,
        snackbar: true,
        products: [],
        search: "",
        loading:
            Vue.prototype.getters().getproducts == null ||
                Vue.prototype.getters().getproducts == "" ||
                Vue.prototype.getters().getproducts == undefined
                ? true
                : false,
        error_msg: null,
        success_msg: null,
        headers: [
            { text: "#", value: "id", align: "center" },
            {
                text: Vue.prototype.langkeyword("NAME"),
                value: Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("CATEGORY"),
                value: 'categories_' + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("SUBCATEGORY"),
                value: 'subcategories_' + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("BRAND"),
                value: 'brands_' + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("PRICE"),
                value: 'price',
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("ACTIONS"),
                value: "edit",
                sortable: false,
                align: "center",
            },
        ],
        CURRENTFORM: "add",
        category_id: null,
        productsObject: {
            user_id: Vue.prototype.dashboardAuthId(),
            avatar: '',
            ku_name: "",
            ar_name: "",
            en_name: "",
            price: "",
            subcategory_id: "",
            brand_id: "",
            age_id: "",
            ku_detail: "",
            ar_detail: "",
            en_detail: "",
        },
    }),
    watch: {
        options: {
            handler() {
                this.readData();
            },
        },
    },
    mounted() {
        this.readData();
    },
    computed: {
        categories() {
            return this.$store.getters.getcategories;
        },
        subcategories() {
            return this.$store.getters.getsubcategories.filter((item) => {
                return item.category_id == this.category_id || item.category_id == null || item.category_id == ''
            });
        },
        brands() {
            return this.$store.getters.getbrands.filter((item) => {
                return item.category_id == this.category_id || item.category_id == null || item.category_id == ''
            });
        },
        ages() {
            return this.$store.getters.getages.filter((item) => {
                return item.category_id == this.category_id || item.category_id == null || item.category_id == ''
            });
        },
    },
    methods: {
        isAdd() {
            if (this.CURRENTFORM === "add") {
                return true;
            } else {
                return false;
            }
        },
        isEdit() {
            if (this.CURRENTFORM === "edit") {
                return true;
            } else {
                return false;
            }
        },
        cleanMessages() {
            this.productsObject = {
                user_id: Vue.prototype.dashboardAuthId(),
                avatar: '',
                ku_name: "",
                ar_name: "",
                en_name: "",
                price: "",
                subcategory_id: "",
                brand_id: "",
                age_id: "",
                ku_detail: "",
                ar_detail: "",
                en_detail: "",
            };
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        validate(params, callback) {
            this.error_msg = null;
            params.forEach((param) => {
                if (param == "" || param == undefined || param == null) {
                    this.error_msg = this.langkeyword("DATA_REQUIRED");
                }
            });
            if (this.error_msg == null) {
                return callback();
            }
            this.cleanMessages();
        },
        editDialog(item) {
            this.category_id = item.category_id
            this.CURRENTFORM = "edit";
            this.productsObject = Object.assign({
                user_id: Vue.prototype.dashboardAuthId(),
                avatar: '',
                ku_name: "",
                ar_name: "",
                en_name: "",
                price: "",
                subcategory_id: "",
                brand_id: "",
                age_id: "",
                ku_detail: "",
                ar_detail: "",
                en_detail: "",
            }, item);
            this.dialog = true;
        },
        lockDialog(item) {
            this.CURRENTFORM = "lock";
            this.productsObject = Object.assign({
                user_id: Vue.prototype.dashboardAuthId(),
                avatar: '',
                ku_name: "",
                ar_name: "",
                en_name: "",
                price: "",
                subcategory_id: "",
                brand_id: "",
                age_id: "",
                ku_detail: "",
                ar_detail: "",
                en_detail: "",
            }, item);
            this.dialog = true;
        },
        deleteDialog(item) {
            this.CURRENTFORM = "delete";
            this.productsObject = Object.assign({
                user_id: Vue.prototype.dashboardAuthId(),
                avatar: '',
                ku_name: "",
                ar_name: "",
                en_name: "",
                price: "",
                subcategory_id: "",
                brand_id: "",
                age_id: "",
                ku_detail: "",
                ar_detail: "",
                en_detail: "",
            }, item);
            this.dialogDelete = true;
        },
        closeMainDialog() {
            this.dialog = false;
            this.$nextTick(() => {
                this.productsObject = Object.assign({
                    user_id: Vue.prototype.dashboardAuthId(),
                    avatar: '',
                    ku_name: "",
                    ar_name: "",
                    en_name: "",
                    price: "",
                    subcategory_id: "",
                    brand_id: "",
                    age_id: "",
                    ku_detail: "",
                    ar_detail: "",
                    en_detail: "",
                }, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        closeDeleteDialog() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.productsObject = Object.assign({
                    user_id: Vue.prototype.dashboardAuthId(),
                    avatar: '',
                    ku_name: "",
                    ar_name: "",
                    en_name: "",
                    price: "",
                    subcategory_id: "",
                    brand_id: "",
                    age_id: "",
                    ku_detail: "",
                    ar_detail: "",
                    en_detail: "",
                }, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        readData() {
            this.products = this.$store.getters.getproducts;
            setTimeout(() => {
                if (!this.permissionExist("#view_products")) {
                    this.$router.push("/dashboard/home");
                }
            }, 500);
        },
        saveProducts() {
            this.validate(
                [
                    this.productsObject.avatar,
                    this.productsObject.ku_name,
                    this.productsObject.ar_name,
                    this.productsObject.en_name,
                    this.productsObject.price,
                    this.productsObject.subcategory_id,
                    this.productsObject.brand_id,
                    this.productsObject.ku_detail,
                    this.productsObject.ar_detail,
                    this.productsObject.en_detail,
                ],
                () => {
                    const avatarname = document.getElementById('avatar').files[0]
                    const addParams = new FormData();
                    addParams.append('addProducts', true)
                    addParams.append('ku_name', this.productsObject.ku_name)
                    addParams.append('ar_name', this.productsObject.ar_name)
                    addParams.append('en_name', this.productsObject.en_name)
                    addParams.append('price', this.productsObject.price)
                    addParams.append('subcategory_id', this.productsObject.subcategory_id)
                    addParams.append('brand_id', this.productsObject.brand_id)
                    addParams.append('age_id', this.productsObject.age_id)
                    addParams.append('ku_detail', this.productsObject.ku_detail)
                    addParams.append('ar_detail', this.productsObject.ar_detail)
                    addParams.append('en_detail', this.productsObject.en_detail)
                    addParams.append('avatar', avatarname)
                    addParams.append('user_id', Vue.prototype.dashboardAuthId())
                    const config = {
                        headers: {
                            'content-type': `multipart/form-data; boundary=${addParams._boundary}`,
                        }
                    }
                    ProductsRequests.add(addParams, config,
                        (res) => {
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.products = res.data.data;
                                this.products = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        updateProducts(productId) {
            this.validate(
                [
                    this.productsObject.ku_name,
                    this.productsObject.ar_name,
                    this.productsObject.en_name,
                    this.productsObject.price,
                    this.productsObject.subcategory_id,
                    this.productsObject.brand_id,
                    this.productsObject.ku_detail,
                    this.productsObject.ar_detail,
                    this.productsObject.en_detail,
                ],
                () => {
                    const avatarname = document.getElementById('avatar').files[0]
                    const editParams = new FormData();
                    editParams.append('editProducts', true)
                    editParams.append('product_id', productId)
                    editParams.append('ku_name', this.productsObject.ku_name)
                    editParams.append('ar_name', this.productsObject.ar_name)
                    editParams.append('en_name', this.productsObject.en_name)
                    editParams.append('price', this.productsObject.price)
                    editParams.append('subcategory_id', this.productsObject.subcategory_id)
                    editParams.append('brand_id', this.productsObject.brand_id)
                    editParams.append('age_id', this.productsObject.age_id)
                    editParams.append('ku_detail', this.productsObject.ku_detail)
                    editParams.append('ar_detail', this.productsObject.ar_detail)
                    editParams.append('en_detail', this.productsObject.en_detail)
                    editParams.append('user_id', Vue.prototype.dashboardAuthId())
                    editParams.append('avatar', avatarname)
                    const config = {
                        headers: {
                            'content-type': `multipart/form-data; boundary=${editParams._boundary}`,
                        }
                    }
                    ProductsRequests.edit(editParams, config,
                        (res) => {
                            console.log(res.data)
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.products = res.data.data;
                                this.products = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        deleteProducts(productId) {
            ProductsRequests.delete({ deleteProducts: true, product_id: productId }, (res) => {
                if (res.data.status === false) {
                    this.error_msg = this.langkeyword(res.data.msg);
                } else if (res.data.status === true) {
                    this.error_msg = null;
                    this.success_msg = this.langkeyword(res.data.msg);
                    this.$store.state.products = res.data.data;
                    this.products = res.data.data;
                }
            });
            this.cleanMessages();
            this.closeDeleteDialog();
        },
        openProductColors(item) {
            this.$router.push('products/colors/' + item.id)
        }
    },
};
</script>