import Vue from 'vue'
import Vuex from 'vuex'
import AppRequests from '../requests/App'
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    actlang: null,
    serverurl: null,
    device: null,
    dashboardauth: {},
    dashboardhome: {},
    setting: {},
    users: [],
    userstokens: [],
    roles: [],
    menus: [],
    menusbypermissions: [],
    pages: [],
    permissions: [],

    ages: [],
    brands: [],
    categories: [],
    colors: [],
    orderstatuses: [],
    sizes: [],
    sliders: [],
    subcategories: [],
    productcoloravatars: [],
    productcolors: [],
    productcolorsizes: [],
    products: [],
    accounts: [],
    orders: [],
    orderitems: [],

    direction: '',
  },
  getters: {
    getdevice(state) {
      return state.device
    },
    getusers(state) {
      return state.users
    },
    getuserstokens(state) {
      return state.userstokens
    },
    getroles(state) {
      return state.roles
    },

    getdashboardauth(state) {
      return state.dashboardauth
    },
    getlang(state) {
      return state.lang
    },
    getdrirection(state) {
      return state.drirection
    },
    getsetting(state) {
      return state.setting
    },
    getdashboardhome(state) {
      return state.dashboardhome
    },
    getmenus(state) {
      return state.menus
    },
    getmenusbypermissions(state) {
      return state.menusbypermissions
    },
    getpages(state) {
      return state.pages
    },
    getpermissions(state) {
      return state.permissions
    },

    getages(state) {
      return state.ages
    },
    getbrands(state) {
      return state.brands
    },
    getcategories(state) {
      return state.categories
    },
    getcolors(state) {
      return state.colors
    },

    getsliders(state) {
      return state.sliders
    },

    getsizes(state) {
      return state.sizes
    },

    getorderstatuses(state) {
      return state.orderstatuses
    },

    getsubcategories(state) {
      return state.subcategories
    },

    getproductcoloravatars(state) {
      return state.productcoloravatars
    },

    getproductcolors(state) {
      return state.productcolors
    },

    getproductcolorsizes(state) {
      return state.productcolorsizes
    },

    getproducts(state) {
      return state.products
    },

    getaccounts(state) {
      return state.accounts
    },

    getorders(state) {
      return state.orders
    },

    getorderitems(state) {
      return state.orderitems
    },

    getcurrentcustomerinvoicedetail(state) {
      return state.currentcustomerinvoicedetail
    },

  },
  mutations: {
    fetchData(state) {
      AppRequests.getAll({ isLoadApp: true }, (res) => {
        state.device = res.data.device
        state.setting = res.data.fetchsetting
        state.users = res.data.getusers
        state.userstokens = res.data.getuserstokens
        state.roles = res.data.getroles
        state.menus = res.data.getmenus
        state.pages = res.data.getpages
        state.permissions = res.data.getpermissions

        state.ages = res.data.getages
        state.brands = res.data.getbrands
        state.categories = res.data.getcategories
        state.colors = res.data.getcolors
        state.orderstatuses = res.data.getorderstatuses
        state.sizes = res.data.getsizes
        state.sliders = res.data.getsliders
        state.subcategories = res.data.getsubcategories
        state.productcoloravatars = res.data.getproductcoloravatars
        state.productcolors = res.data.getproductcolors
        state.productcolorsizes = res.data.getproductcolorsizes
        state.products = res.data.getproducts
        state.accounts = res.data.getaccounts
        state.orders = res.data.getorders
        state.orderitems = res.data.getorderitems

      })
      AppRequests.countAll({ isLoadApp: true }, (res) => {
        state.dashboardhome = res.data
      })
    }
  },
  actions: {

  },
  modules: {
  }
})
