<template>
  <div>
    <navbar></navbar>

    <v-container>
      <v-row no-gutters>
        <v-col cols="6" xs="5" sm="4" md="3" lg="3" xl="2" v-for="(item, index) in cards" :key="index"
          :class="item.permissionexist == true ? 'm-auto' : 'd-none'">
          <v-card elevation="10" link :to="item.link" shaped class="p-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <div class="fs-small nzar">
                  {{ item.name }}
                </div>
                <v-list-item-avatar tile size="35" class="rounded">
                  <v-icon class="text-main" :color="item.color" large>{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="text-h6 mt-1">
                  {{ item.num }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="mt-16"></div>
    <dashboardFooter />
  </div>
</template>

<script>
import navbar from "../../layouts/dashboard/nav.vue";
import NavRequests from "../../requests/dashboard/Nav";
import dashboardFooter from "../../layouts/dashboard/dashboardFooter.vue";
export default {
  data() {
    return {
      cards: [],
    };
  },
  components: {
    navbar,
    dashboardFooter,
  },
  methods: {

    authCheck() {
      NavRequests.checkAuth(
        {
          isAuthCheck: true,
          user_id: this.dashboardAuthId(),
          token: this.dashboardAuthToken(),
        },
        (res) => {
          if (res.data.status === false) {
            this.$router.push("/dashboard/auth/login");
          } else if (res.data.status === true) {
            this.$store.state.dashboardauth = res.data.data;
            this.menus = res.data.menus
            this.avatar = this.$store.state.dashboardauth.avatar;
            this.username = this.$store.state.dashboardauth.name;
            this.logo = this.$store.state.setting.logo;
          }
        }
      );
    },

    fetchCards() {
      setTimeout(() => {
        this.cards = [
          {
            name: this.langkeyword("USERS"),
            icon: "mdi-account",
            num: this.$store.state.dashboardhome.countusers - 1,
            color: "darken-1",
            link: "/dashboard/account/users",
            permissionexist: this.permissionExist('#view_users')
          },
          {
            name: this.langkeyword("ROLES"),
            icon: "mdi-security",
            num: this.$store.state.dashboardhome.countroles,
            color: "darken-1",
            link: "/dashboard/account/roles",
            permissionexist: this.permissionExist('#view_roles')
          },
          {
            name: this.langkeyword("CATEGORIES"),
            icon: "mdi-view-list",
            num: this.$store.state.dashboardhome.countcategories,
            color: "darken-1",
            link: "/dashboard/subs/categories",
            permissionexist: this.permissionExist('#view_categories')
          },
          {
            name: this.langkeyword("SUBCATEGORIES"),
            icon: "mdi mdi-format-list-bulleted",
            num: this.$store.state.dashboardhome.countsubcategories,
            color: "darken-1",
            link: "/dashboard/subs/subcategories",
            permissionexist: this.permissionExist('#view_subcategories')
          },
          {
            name: this.langkeyword("BRANDS"),
            icon: "mdi mdi-watermark",
            num: this.$store.state.dashboardhome.countbrands,
            color: "darken-1",
            link: "/dashboard/subs/brands",
            permissionexist: this.permissionExist('#view_brands')
          },
          {
            name: this.langkeyword("AGES"),
            icon: "mdi mdi-human-male-female-child",
            num: this.$store.state.dashboardhome.countages,
            color: "darken-1",
            link: "/dashboard/subs/ages",
            permissionexist: this.permissionExist('#view_ages')
          },
          {
            name: this.langkeyword("ORDERSTATUSES"),
            icon: "mdi mdi-list-status",
            num: this.$store.state.dashboardhome.countorderstatuses,
            color: "darken-1",
            link: "/dashboard/subs/orderstatuses",
            permissionexist: this.permissionExist('#view_orderstatuses')
          },
          {
            name: this.langkeyword("COLORS"),
            icon: "mdi mdi-invert-colors",
            num: this.$store.state.dashboardhome.countcolors,
            color: "darken-1",
            link: "/dashboard/subs/colors",
            permissionexist: this.permissionExist('#view_colors')
          },
          {
            name: this.langkeyword("SIZES"),
            icon: "mdi mdi-size-xxs",
            num: this.$store.state.dashboardhome.countsizes,
            color: "darken-1",
            link: "/dashboard/subs/sizes",
            permissionexist: this.permissionExist('#view_sizes')
          },
          {
            name: this.langkeyword("SLIDERS"),
            icon: "mdi mdi-image-area",
            num: this.$store.state.dashboardhome.countsliders,
            color: "darken-1",
            link: "/dashboard/subs/sliders",
            permissionexist: this.permissionExist('#view_sliders')
          },
          {
            name: this.langkeyword("PRODUCTS"),
            icon: "mdi-basket",
            num: this.$store.state.dashboardhome.countproducts,
            color: "darken-1",
            link: "/dashboard/product/products",
            permissionexist: this.permissionExist('#view_products')
          },
          {
            name: this.langkeyword("ACCOUNTS"),
            icon: "mdi mdi-account-multiple",
            num: this.$store.state.dashboardhome.countaccounts,
            color: "darken-1",
            link: "/dashboard/customer/accounts",
            permissionexist: this.permissionExist('#view_accounts')
          },
          {
            name: this.langkeyword("ORDERS"),
            icon: "mdi mdi-cart-plus",
            num: this.$store.state.dashboardhome.countorders,
            color: "darken-1",
            link: "/dashboard/customer/orders",
            permissionexist: this.permissionExist('#view_orders')
          },
        ]
      }, 1000);
    }
  },
  mounted() {
    this.fetchCards()
    this.authCheck()
  },
};
</script>


<style>
@font-face {
  font-family: "nzar";
  src: local("nzar"), url(../../assets/fonts/nzar.ttf) format("truetype");
}

.radius-rounded {
  border-radius: 15px !important;
}

.nzar {
  font-family: "nzar" !important;
}

.fs-small {
  font-size: 0.9rem;
}

.theme--dark .dashboard .home-card:hover {
  background: #393b56 !important;
  transition: ease 0.2s !important;
}

.theme--light .dashboard .home-card:hover {
  background: #eceaea !important;
  transition: ease 0.2s !important;
}
</style>