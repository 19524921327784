import axios from 'axios'


export default {

    async getAll(params, callback) {
        return await axios.post("/app/dashboard/customer/accounts/getall", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async getOne(params, callback) {
        return await axios.post("/app/dashboard/customer/accounts/getone", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async delete(params, callback) {
        return await axios.post("/app/dashboard/customer/accounts/delete", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



}

