import Vue from 'vue'
import VueRouter from 'vue-router'


// client
import clienthome from '../pages/client/home.vue'
//////////////////////////////////////////////////////////////////////////////////////////////////////////////






// dashboard
import dashboardhome from '../pages/dashboard/home.vue'
import dashboardauth_login from '@/pages/dashboard/auth/login.vue'
import dashboardauth_forget from '@/pages/dashboard/auth/forget.vue'
import dashboardauth_reset from '@/pages/dashboard/auth/reset.vue'

import dashboardprofile from '@/pages/dashboard/profile.vue'
import dashboardusers from '@/pages/dashboard/account/users.vue'
import dashboardroles from '@/pages/dashboard/account/roles.vue'
import dashboardroles_profile from '@/pages/dashboard/account/roles_profile.vue'


import system_settings from '../pages/dashboard/system/settings.vue'
import system_menus from '../pages/dashboard/system/menus.vue'
import system_pages from '../pages/dashboard/system/pages.vue'
import system_permissions from '../pages/dashboard/system/permissions.vue'



import subs_orderstatuses from '../pages/dashboard/subs/orderstatuses.vue'
import subs_categories from '../pages/dashboard/subs/categories.vue'
import subs_subcategories from '../pages/dashboard/subs/subcategories.vue'
import subs_brands from '../pages/dashboard/subs/brands.vue'
import subs_ages from '../pages/dashboard/subs/ages.vue'
import subs_colors from '../pages/dashboard/subs/colors.vue'
import subs_sizes from '../pages/dashboard/subs/sizes.vue'
import subs_sliders from '../pages/dashboard/subs/sliders.vue'



import product_products from '../pages/dashboard/product/products.vue'
import product_productcolors from '../pages/dashboard/product/productcolors.vue'
import product_productcoloravatars from '../pages/dashboard/product/productcoloravatars.vue'
import product_productcolorsizes from '../pages/dashboard/product/productcolorsizes.vue'


import customer_accounts from '../pages/dashboard/customer/accounts.vue'
import customer_accountdetail from '../pages/dashboard/customer/accountdetail.vue'
import customer_orders from '../pages/dashboard/customer/orders.vue'
import customer_orderdetail from '../pages/dashboard/customer/orderdetail.vue'
import customer_report from '../pages/dashboard/customer/report.vue'


import notFound from '@/pages/404.vue'
Vue.use(VueRouter)



const routes = [
  {
    path: '*',
    name: 'notFound',
    component: notFound,
    meta: {
      hideNavbar: true,
    }
  },










  // dashboard
  {
    path: '/dashboard/home',
    name: 'DashboardHome',
    component: dashboardhome,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/',
    name: 'DashboardRootHome',
    component: dashboardhome,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/auth/login',
    name: 'DashboardAuthLogin',
    component: dashboardauth_login,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/auth/forget',
    name: 'DashboardAuthForget',
    component: dashboardauth_forget,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/auth/reset',
    name: 'DashboardAuthReset',
    component: dashboardauth_reset,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/profile',
    name: 'DashboardProfile',
    component: dashboardprofile,
    meta: {
      hideNavbar: true,
    }
  },
  // ----------------------------------------------------------------------------------------------------------------
  {
    path: '/dashboard/account/users',
    name: 'DashboardUsers',
    component: dashboardusers,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/account/roles',
    name: 'DashboardRoles',
    component: dashboardroles,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/account/roles/:id',
    name: 'DashboardRolesProfile',
    component: dashboardroles_profile,
    meta: {
      hideNavbar: true,
    }
  },
  // ----------------------------------------------------------------------------------------------------------------
  {
    path: '/dashboard/system/settings',
    name: 'DashboardSystemSettings',
    component: system_settings,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/system/menus',
    name: 'DashboardSystemMenus',
    component: system_menus,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/system/pages',
    name: 'DashboardSystemPages',
    component: system_pages,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/system/permissions',
    name: 'DashboardSystemPermissions',
    component: system_permissions,
    meta: {
      hideNavbar: true,
    }
  },
  // ----------------------------------------------------------------------------------------------------------------
  {
    path: '/dashboard/subs/orderstatuses',
    name: 'DashboardSubsOrderStatuses',
    component: subs_orderstatuses,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/categories',
    name: 'DashboardSubsCategories',
    component: subs_categories,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/subcategories',
    name: 'DashboardSubsSubCategories',
    component: subs_subcategories,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/brands',
    name: 'DashboardSubsBrands',
    component: subs_brands,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/ages',
    name: 'DashboardSubsAges',
    component: subs_ages,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/colors',
    name: 'DashboardSubsColors',
    component: subs_colors,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/sizes',
    name: 'DashboardSubsSizes',
    component: subs_sizes,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/sliders',
    name: 'DashboardSubsSliders',
    component: subs_sliders,
    meta: {
      hideNavbar: true,
    }
  },
  // ----------------------------------------------------------------------------------------------------------------





  // ----------------------------------------------------------------------------------------------------------------
  {
    path: '/dashboard/product/products',
    name: 'DashboardProductProducts',
    component: product_products,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/product/products/colors/:id',
    name: 'DashboardProductProductColors',
    component: product_productcolors,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/product/products/colors/:id/avatars/:pai',
    name: 'DashboardProductProductColorAvatars',
    component: product_productcoloravatars,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/product/products/colors/:id/sizes/:pci',
    name: 'DashboardProductProductColorSizes',
    component: product_productcolorsizes,
    meta: {
      hideNavbar: true,
    }
  },
  // ----------------------------------------------------------------------------------------------------------------




  // ----------------------------------------------------------------------------------------------------------------
  {
    path: '/dashboard/customer/accounts',
    name: 'DashboardCustomersAccounts',
    component: customer_accounts,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/customer/accounts/:id',
    name: 'DashboardCustomersAccountDetail',
    component: customer_accountdetail,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/customer/orders',
    name: 'DashboardCustomersOrders',
    component: customer_orders,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/customer/orders/:id',
    name: 'DashboardCustomersOrderDetail',
    component: customer_orderdetail,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/customer/report',
    name: 'DashboardCustomersReport',
    component: customer_report,
    meta: {
      hideNavbar: true,
    }
  },
  // ----------------------------------------------------------------------------------------------------------------





  // client
  {
    path: '/',
    name: 'ClientHome',
    component: clienthome
  },






]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
