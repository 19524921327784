<template>
    <div>
        <navbar></navbar>
        <v-container fluid class="mt-4 mb-4">
            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>



            <v-row class="text-center">
                <v-col cols="12 text-start mx-auto" sm="11">
                    <v-btn color="green" @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                </v-col>

                <v-col cols="12 text-start mx-auto" sm="11">
                    <v-card>

                        <v-card-title class="green darken-1 mx-auto">
                            <span class="white--text">{{
                        langkeyword("PRODUCT")
                    }}</span>
                        </v-card-title>
                        <v-list>

                            <v-list-item>
                                <v-list-item-action> </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ langkeyword("NAME") }} :
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span v-if="lang() === 'ku'">{{ productcolorinfo.products_ku_name }}</span>
                                    <span v-if="lang() === 'ar'">{{ productcolorinfo.products_ar_name }}</span>
                                    <span v-if="lang() === 'en'">{{ productcolorinfo.products_en_name }}</span>
                                </v-list-item-action>
                            </v-list-item>


                            <v-list-item>
                                <v-list-item-action> </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ langkeyword("COLOR") }} :
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span v-if="lang() === 'ku'">{{ productcolorinfo.colors_ku_name }}</span>
                                    <span v-if="lang() === 'ar'">{{ productcolorinfo.colors_ar_name }}</span>
                                    <span v-if="lang() === 'en'">{{ productcolorinfo.colors_en_name }}</span>
                                </v-list-item-action>
                            </v-list-item>



                        </v-list>
                    </v-card>
                </v-col>
            </v-row>


            <v-row class="d-flex text-main justify-center">
                <v-col cols="11">
                    <v-data-table :headers="headers" :items="productcolorsizes" :loading="loading" :search="search"
                        sort-by="name" class="elevation-1 nzar-font radius-15" :footer-props="{
                        showFirstLastPage: true,
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                        'items-per-page-text': langkeyword('PERPAGE'),
                        'items-per-page-options': perPages(),
                    }" :items-per-page="10">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title><v-icon>mdi-3xl</v-icon>
                                    {{ langkeyword("SIZES") }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialog" max-width="700px" persistent>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="success" :style="permissionExist('#add_products')
                        ? 'color: white;float:right'
                        : 'display:none;'
                        " class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                                            <v-icon> mdi-plus-circle </v-icon>
                                            {{ langkeyword("ADD") }}
                                        </v-btn>
                                    </template>
                                    <v-card class="radius-15 bg-mode">
                                        <v-card-title>
                                            <span class="nzar-font" v-if="isAdd() === true"><v-icon>
                                                    mdi-plus-circle</v-icon>
                                                {{ langkeyword("ADD") }}</span>
                                            <span class="nzar-font" v-if="isEdit() === true"><v-icon>
                                                    mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}</span>
                                        </v-card-title>
                                        <v-card-text class="radius-15">
                                            <v-container>
                                                <v-form>
                                                    <v-select class="d-none"
                                                        v-if="isAdd() === true || isEdit() === true"
                                                        v-model="productcolorinfo.id" :items="productcolors"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('COLOR')" dense></v-select>

                                                    <v-select v-if="isAdd() === true || isEdit() === true"
                                                        v-model="productcolorsizesObject.size_id" :items="sizes"
                                                        :item-text="langoptions('value') + '_name'" item-value="id"
                                                        :label="langkeyword('SIZE')" dense></v-select>


                                                    <v-text-field class="nzar-font" type="number" min="0"
                                                        v-model="productcolorsizesObject.qty"
                                                        :label="langkeyword('QUANTITY')">
                                                    </v-text-field>

                                                </v-form>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn class="bg-main white--text" v-if="isAdd() === true" dark
                                                @click="saveProductColorSizes">
                                                <v-icon dark> mdi-plus-circle</v-icon>
                                                {{ langkeyword("SAVE") }}
                                            </v-btn>
                                            <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                                                @click="updateProductColorSizes(productcolorsizesObject.id)">
                                                <v-icon dark> mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" dark @click="closeMainDialog">
                                                <v-icon dark>mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <v-icon>mdi-delete-circle</v-icon>
                                            {{ langkeyword("DELETE") }}
                                        </v-card-title>
                                        <v-card-text>
                                            {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="red lighten-2" dark
                                                @click="deleteProductColorSizes(productcolorsizesObject.id)">
                                                <v-icon>mdi-delete-circle</v-icon>
                                                {{ langkeyword("DELETE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                                                <v-icon> mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-spacer></v-spacer>

                                <v-row>
                                    <v-col cols="12" md="10">
                                        <v-text-field v-if="permissionExist('#search_products')" class="nzar-font"
                                            v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                                            hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon v-if="permissionExist('#edit_products')" small
                                class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                                mdi-pencil-circle
                            </v-icon>
                            <v-icon v-if="permissionExist('#delete_products')" small
                                class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                                mdi-delete-circle
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
import Vue from "vue";
import ProductColorsRequests from "../../../requests/dashboard/product/ProductColors";
import ProductColorSizesRequests from "../../../requests/dashboard/product/ProductColorSizes";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data: () => ({
        productcolorinfo: {},
        dialog: false,
        dialogDelete: false,
        snackbar: true,
        search: "",
        loading:
            Vue.prototype.getters().getproductcolorsizes == null ||
                Vue.prototype.getters().getproductcolorsizes == "" ||
                Vue.prototype.getters().getproductcolorsizes == undefined
                ? true
                : false,
        error_msg: null,
        success_msg: null,
        headers: [
            {
                text: Vue.prototype.langkeyword("SIZE"),
                value: "sizes_" + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("QUANTITY"),
                value: "qty",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("ACTIONS"),
                value: "edit",
                sortable: false,
                align: "center",
            },
        ],
        CURRENTFORM: "add",
        productcolorsizesObject: {
            user_id: Vue.prototype.dashboardAuthId(),
            qty: "",
            size_id: '',
            productcolor_id: '',
        },
    }),
    watch: {
        options: {
            handler() {
                this.readData();
            },
        },
    },
    mounted() {
        this.readData();
    },
    computed: {
        productcolorsizes() {
            return this.$store.getters.getproductcolorsizes.filter(item => {
                return item.productcolor_id == this.$route.params.pci
            })
        },
        sizes() {
            return this.$store.getters.getsizes.filter(item => {
                return (item.category_id == this.productcolorinfo.products_category_id) || (item.category_id == null) || (item.category_id == '')
            });
        },
        productcolors() {
            return this.$store.getters.getproductcolors;
        },
    },
    methods: {
        isAdd() {
            if (this.CURRENTFORM === "add") {
                return true;
            } else {
                return false;
            }
        },
        isEdit() {
            if (this.CURRENTFORM === "edit") {
                return true;
            } else {
                return false;
            }
        },
        cleanMessages() {
            this.productcolorsizesObject = { user_id: Vue.prototype.dashboardAuthId() };
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        validate(params, callback) {
            this.error_msg = null;
            params.forEach((param) => {
                if (param == "" || param == undefined || param == null) {
                    this.error_msg = this.langkeyword("DATA_REQUIRED");
                }
            });
            if (this.error_msg == null) {
                return callback();
            }
            this.cleanMessages();
        },
        editDialog(item) {
            this.CURRENTFORM = "edit";
            this.productcolorsizesObject = Object.assign({}, item);
            this.dialog = true;
        },
        lockDialog(item) {
            this.CURRENTFORM = "lock";
            this.productcolorsizesObject = Object.assign({}, item);
            this.dialog = true;
        },
        deleteDialog(item) {
            this.CURRENTFORM = "delete";
            this.productcolorsizesObject = Object.assign({}, item);
            this.dialogDelete = true;
        },
        closeMainDialog() {
            this.dialog = false;
            this.$nextTick(() => {
                this.productcolorsizesObject = Object.assign({}, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        closeDeleteDialog() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.productcolorsizesObject = Object.assign({}, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        readData() {


            ProductColorsRequests.getOne(
                {
                    getOneProductColors: true,
                    productcolor_id: this.$route.params.pci,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                        setTimeout(() => {
                            this.$router.push("/*");
                        }, 1000);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.productcolorsizesObject.productcolor_id = res.data.data.id;
                        this.productcolorinfo = res.data.data;
                    }
                    this.cleanMessages();
                }
            ),




                // this.productcolorsizes = this.$store.getters.getproductcolorsizes.filter(pc => {
                //     return pc.productcolor_id == this.$route.params.pci
                // });
                setTimeout(() => {
                    if (!this.permissionExist("#view_products")) {
                        this.$router.push("/dashboard/home");
                    }
                }, 500);

        },
        saveProductColorSizes() {
            this.validate(
                [
                    this.$route.params.pci,
                    this.productcolorsizesObject.size_id,
                    this.productcolorsizesObject.qty,
                ],
                () => {

                    ProductColorSizesRequests.add(
                        {
                            addProductColorSizes: true,
                            size_id: this.productcolorsizesObject.size_id,
                            qty: this.productcolorsizesObject.qty,
                            productcolor_id: this.$route.params.pci,
                            user_id: Vue.prototype.dashboardAuthId(),
                        },
                        (res) => {
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.productcolorsizes = res.data.data;
                                this.productcolorsizes = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        updateProductColorSizes(productcolorsizeId) {
            this.validate(
                [
                    this.$route.params.pci,
                    this.productcolorsizesObject.qty,
                    this.productcolorsizesObject.size_id,
                ],
                () => {
                    ProductColorSizesRequests.edit(
                        {
                            editProductColorSizes: true,
                            productcolorsize_id: productcolorsizeId,
                            qty: this.productcolorsizesObject.qty,
                            size_id: this.productcolorsizesObject.size_id,
                            productcolor_id: this.$route.params.pci,
                            user_id: Vue.prototype.dashboardAuthId()
                        },
                        (res) => {
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.productcolorsizes = res.data.data;
                                this.productcolorsizes = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        deleteProductColorSizes(productcolorsizeId) {
            ProductColorSizesRequests.delete(
                { deleteProductColorSizes: true, productcolorsize_id: productcolorsizeId },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.productcolorsizes = res.data.data;
                        this.productcolorsizes = res.data.data;
                    }
                }
            );
            this.cleanMessages();
            this.closeDeleteDialog();
        },
    },
};
</script>