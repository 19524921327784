<template>
    <div>
        <navbar></navbar>
        <v-container fluid class="mt-4 mb-4">
            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>


            <v-row class="text-center">
                <v-col cols="12 text-start mx-auto" sm="11">
                    <v-btn color="green" @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                </v-col>

                <v-col cols="12 text-start mx-auto" sm="11">
                    <v-card>

                        <v-card-title class="green darken-1 mx-auto">
                            <span class="white--text">{{
                        langkeyword("ORDER")
                    }}</span>
                        </v-card-title>
                        <v-list>

                            <v-list-item>
                                <v-list-item-action> </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ langkeyword("FULLNAME") }} :
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ orderinfo.accounts_fullname }}
                                    <v-btn color="primary" small link
                                        :to="'/dashboard/customer/accounts/' + orderinfo.account_id"><span
                                            class="mdi mdi-account"></span></v-btn>
                                </v-list-item-action>
                            </v-list-item>


                            <v-list-item>
                                <v-list-item-action> </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ langkeyword("CODE") }} :
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ orderinfo.ordercode }}
                                </v-list-item-action>
                            </v-list-item>


                            <v-list-item>
                                <v-list-item-action> </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ langkeyword("ORDERSTATUS") }} :
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span v-if="lang() === 'ku'">{{ orderinfo.orderstatuses_ku_name }}</span>
                                    <span v-if="lang() === 'ar'">{{ orderinfo.orderstatuses_ar_name }}</span>
                                    <span v-if="lang() === 'en'">{{ orderinfo.orderstatuses_en_name }}</span>
                                </v-list-item-action>
                            </v-list-item>




                            <v-list-item>
                                <v-list-item-action> </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ langkeyword("TOTAL") }} :
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ orderinfo.amount }}
                                </v-list-item-action>
                            </v-list-item>



                        </v-list>
                    </v-card>
                </v-col>
            </v-row>


            <v-row class="d-flex text-main justify-center">
                <v-col cols="11">
                    <v-data-table :headers="headers" :items="orderitems" :loading="loading" :search="search"
                        sort-by="name" class="elevation-1 nzar-font radius-15" :footer-props="{
                        showFirstLastPage: true,
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                        'items-per-page-text': langkeyword('PERPAGE'),
                        'items-per-page-options': perPages(),
                    }" :items-per-page="10">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title><v-icon>mdi-invoice-list</v-icon>
                                    {{ langkeyword("ORDERITEMS") }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <v-icon>mdi-delete-circle</v-icon>
                                            {{ langkeyword("DELETE") }}
                                        </v-card-title>
                                        <v-card-text>
                                            {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="red lighten-2" dark
                                                @click="deleteOrderItems(orderitemsObject.id)">
                                                <v-icon>mdi-delete-circle</v-icon>
                                                {{ langkeyword("DELETE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                                                <v-icon> mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-spacer></v-spacer>

                                <v-row>
                                    <v-col cols="12" md="10">
                                        <v-text-field v-if="permissionExist('#search_orders')" class="nzar-font"
                                            v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                                            hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon small class="mr-2 info white--text px-2 py-2 rounded" @click="openImage(item)">
                                mdi-image
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
import Vue from "vue";
import axios from 'axios'
import OrdersRequests from "../../../requests/dashboard/customer/Orders";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data: () => ({
        orderinfo: {},
        dialog: false,
        dialogDelete: false,
        snackbar: true,
        search: "",
        loading:
            Vue.prototype.getters().getorderitems == null ||
                Vue.prototype.getters().getorderitems == "" ||
                Vue.prototype.getters().getorderitems == undefined
                ? true
                : false,
        error_msg: null,
        success_msg: null,
        headers: [
            {
                text: Vue.prototype.langkeyword("PRODUCT"),
                value: "products_" + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("COLOR"),
                value: "colors_" + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("SIZE"),
                value: "sizes_" + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("QUANTITY"),
                value: "qty",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("PRICE"),
                value: "price",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("TOTAL"),
                value: 'subtotal',
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("ACTIONS"),
                value: "edit",
                sortable: false,
                align: "center",
            },
        ],
        CURRENTFORM: "add",
        orderitemsObject: {
            user_id: Vue.prototype.dashboardAuthId(),
            order_id: '',
        },
    }),
    watch: {
        options: {
            handler() {
                this.readData();
            },
        },
    },
    mounted() {
        this.readData();
    },
    computed: {
        orderitems() {
            return this.$store.getters.getorderitems.filter(item => {
                return item.order_id == this.$route.params.id
            })
        },
        orders() {
            return this.$store.getters.getorders;
        },
    },
    methods: {
        isAdd() {
            if (this.CURRENTFORM === "add") {
                return true;
            } else {
                return false;
            }
        },
        isEdit() {
            if (this.CURRENTFORM === "edit") {
                return true;
            } else {
                return false;
            }
        },
        cleanMessages() {
            this.orderitemsObject = { user_id: Vue.prototype.dashboardAuthId() };
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        validate(params, callback) {
            this.error_msg = null;
            params.forEach((param) => {
                if (param == "" || param == undefined || param == null) {
                    this.error_msg = this.langkeyword("DATA_REQUIRED");
                }
            });
            if (this.error_msg == null) {
                return callback();
            }
            this.cleanMessages();
        },
        editDialog(item) {
            this.CURRENTFORM = "edit";
            this.orderitemsObject = Object.assign({}, item);
            this.dialog = true;
        },
        lockDialog(item) {
            this.CURRENTFORM = "lock";
            this.orderitemsObject = Object.assign({}, item);
            this.dialog = true;
        },
        deleteDialog(item) {
            this.CURRENTFORM = "delete";
            this.orderitemsObject = Object.assign({}, item);
            this.dialogDelete = true;
        },
        closeMainDialog() {
            this.dialog = false;
            this.$nextTick(() => {
                this.orderitemsObject = Object.assign({}, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        closeDeleteDialog() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.orderitemsObject = Object.assign({}, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        readData() {


            OrdersRequests.getOne(
                {
                    getOneOrders: true,
                    order_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                        setTimeout(() => {
                            this.$router.push("/*");
                        }, 1000);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.orderitemsObject.order_id = res.data.data.id;
                        this.orderinfo = res.data.data;
                    }
                    this.cleanMessages();
                }
            ),




                // this.orderitems = this.$store.getters.getorderitems.filter(pc => {
                //     return pc.order_id == this.$route.params.id
                // });
                setTimeout(() => {
                    if (!this.permissionExist("#view_orders")) {
                        this.$router.push("/dashboard/home");
                    }
                }, 500);

        },
        openImage(item) {
            window.open(
                axios.defaults.baseURL + item.products_avatar,
                '_blank'
            );
        },
    },
};
</script>